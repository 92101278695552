<template>
  <app-module-view>
    <template slot="body">
      <div class="m-b-10">
        <div class="row">
          <div class="col-6">
            <label class="btn btn-secondary" :class="{ 'active': activeTab === 'churnRate' }">
              <input type="radio" v-model="activeTab" value="churnRate" id="activeTab_churnRate">
              {{ $t('eshopDashboard.tab.churn_rate') }}
            </label>
            <label class="btn btn-secondary" :class="{ 'active': activeTab === 'paymentStats' }">
              <input type="radio" v-model="activeTab" value="paymentStats" id="activeTab_paymentStats">
              {{ $t('eshopDashboard.tab.payment') }}
            </label>
          </div>
        </div>
      </div>

      <app-churn-rate-filter v-if="activeTab === 'churnRate'"></app-churn-rate-filter>

      <div class="card card-body" v-if="activeTab === 'churnRate'">
        <table v-if="isChurnRateLoaded" class="table table-striped table-bordered">
          <thead>
          <tr>
            <td></td>
            <td v-for="(month, index) in months" :key="`item-${index}`" class="font-weight-bold">{{ month }}</td>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td class="font-weight-bold">{{ $t('eshopDashboard.customers_at_start') }}</td>
            <td v-for="(month, index) in months" :key="`item-${index}`">{{ getMonthlyChurnRateValue(index + 1, 'start') }}</td>
          </tr>
          <tr>
            <td class="font-weight-bold">{{ $t('eshopDashboard.customers_at_end') }}</td>
            <td v-for="(month, index) in months" :key="`item-${index}`">{{ getMonthlyChurnRateValue(index + 1, 'end') }}</td>
          </tr>
          <tr>
            <td class="font-weight-bold">{{ $t('eshopDashboard.customers_at_end_Check') }}</td>
            <td v-for="(month, index) in months" :key="`item-${index}`">{{ getMonthlyChurnRateValue(index + 1, 'endCheck') }}</td>
          </tr>
          <tr>
            <td class="font-weight-bold">{{ $t('eshopDashboard.churn_customers') }}</td>
            <td v-for="(month, index) in months" :key="`item-${index}`">{{ getMonthlyChurnRateValue(index + 1, 'churn') }}</td>
          </tr>
          <tr>
            <td class="font-weight-bold">{{ $t('eshopDashboard.new_customers') }}</td>
            <td v-for="(month, index) in months" :key="`item-${index}`">{{ getMonthlyChurnRateValue(index + 1, 'new') }}</td>
          </tr>
          <tr>
            <td class="font-weight-bold">{{ $t('eshopDashboard.new_churn_customers') }}</td>
            <td v-for="(month, index) in months" :key="`item-${index}`">{{ getMonthlyChurnRateValue(index + 1, 'newChurn') }}</td>
          </tr>
          <tr>
            <td class="font-weight-bold">{{ $t('eshopDashboard.new_customers_duplicity') }}</td>
            <td v-for="(month, index) in months" :key="`item-${index}`">{{ getMonthlyChurnRateValue(index + 1, 'newDuplicity') }}</td>
          </tr>
          <tr>
            <td class="font-weight-bold">{{ $t('eshopDashboard.customers_with_other_products') }}</td>
            <td v-for="(month, index) in months" :key="`item-${index}`">{{ getMonthlyChurnRateValue(index + 1, 'otherProduct') }}</td>
          </tr>
          <tr>
            <td class="font-weight-bold">{{ $t('eshopDashboard.monthly_churn_rate') }}</td>
            <td v-for="(month, index) in months" :key="`item-${index}`" class="font-weight-bold">
              {{ getMonthlyChurnRateValue(index + 1, 'churnRate') }}
            </td>
          </tr>
          </tbody>
        </table>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <div class="card card-body" v-if="activeTab === 'churnRate'">
            <table v-if="isChurnRateLoaded" class="table table-striped table-bordered">
              <thead>
              <tr>
                <td></td>
                <td v-for="(quarter, index) in quarters" :key="`item-${index}`" class="font-weight-bold">{{
                    $t('eshopDashboard.quarter.' + quarter)
                  }}
                </td>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td class="font-weight-bold">{{ $t('eshopDashboard.customers_at_start') }}</td>
                <td v-for="(quarter, index) in quarters" :key="`item-${index}`">{{ getChurnRateValue(quarter, 'start') }}</td>
              </tr>
              <tr>
                <td class="font-weight-bold">{{ $t('eshopDashboard.customers_at_end') }}</td>
                <td v-for="(quarter, index) in quarters" :key="`item-${index}`">{{ getChurnRateValue(quarter, 'end') }}</td>
              </tr>
              <tr>
                <td class="font-weight-bold">{{ $t('eshopDashboard.customers_at_end_Check') }}</td>
                <td v-for="(quarter, index) in quarters" :key="`item-${index}`">{{ getChurnRateValue(quarter, 'endCheck') }}</td>
              </tr>
              <tr>
                <td class="font-weight-bold">{{ $t('eshopDashboard.churn_customers') }}</td>
                <td v-for="(quarter, index) in quarters" :key="`item-${index}`">{{ getChurnRateValue(quarter, 'churn') }}</td>
              </tr>
              <tr>
                <td class="font-weight-bold">{{ $t('eshopDashboard.new_customers') }}</td>
                <td v-for="(quarter, index) in quarters" :key="`item-${index}`">{{ getChurnRateValue(quarter, 'new') }}</td>
              </tr>
              <tr>
                <td class="font-weight-bold">{{ $t('eshopDashboard.new_churn_customers') }}</td>
                <td v-for="(quarter, index) in quarters" :key="`item-${index}`">{{ getChurnRateValue(quarter, 'newChurn') }}</td>
              </tr>
              <tr>
                <td class="font-weight-bold">{{ $t('eshopDashboard.new_customers_duplicity') }}</td>
                <td v-for="(quarter, index) in quarters" :key="`item-${index}`">{{ getChurnRateValue(quarter, 'newDuplicity') }}</td>
              </tr>
              <tr>
                <td class="font-weight-bold">{{ $t('eshopDashboard.customers_with_other_products') }}</td>
                <td v-for="(quarter, index) in quarters" :key="`item-${index}`">{{ getChurnRateValue(quarter, 'otherProduct') }}</td>
              </tr>
              <tr>
                <td class="font-weight-bold">{{ $t('eshopDashboard.churn_rate') }}</td>
                <td v-for="(quarter, index) in quarters" :key="`item-${index}`" class="font-weight-bold">{{
                    getChurnRateValue(quarter, 'churnRate')
                  }}
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="col-lg-6">
          <div class="card card-body" v-if="activeTab === 'churnRate'">
            <table v-if="isChurnRateLoaded" class="table table-striped table-bordered">
              <thead>
              <tr>
                <td></td>
                <td class="font-weight-bold">{{ getChurnRateValue('annual', 'year') }}</td>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td class="font-weight-bold">{{ $t('eshopDashboard.customers_at_start') }}</td>
                <td>{{ getChurnRateValue('annual', 'start') }}</td>
              </tr>
              <tr>
                <td class="font-weight-bold">{{ $t('eshopDashboard.customers_at_end') }}</td>
                <td>{{ getChurnRateValue('annual', 'end') }}</td>
              </tr>
              <tr>
                <td class="font-weight-bold">{{ $t('eshopDashboard.customers_at_end_Check') }}</td>
                <td>{{ getChurnRateValue('annual', 'endCheck') }}</td>
              </tr>
              <tr>
                <td class="font-weight-bold">{{ $t('eshopDashboard.churn_customers') }}</td>
                <td>{{ getChurnRateValue('annual', 'churn') }}</td>
              </tr>
              <tr>
                <td class="font-weight-bold">{{ $t('eshopDashboard.new_customers') }}</td>
                <td>{{ getChurnRateValue('annual', 'new') }}</td>
              </tr>
              <tr>
                <td class="font-weight-bold">{{ $t('eshopDashboard.new_churn_customers') }}</td>
                <td>{{ getChurnRateValue('annual', 'newChurn') }}</td>
              </tr>
              <tr>
                <td class="font-weight-bold">{{ $t('eshopDashboard.new_customers_duplicity') }}</td>
                <td>{{ getChurnRateValue('annual', 'newDuplicity') }}</td>
              </tr>
              <tr>
                <td class="font-weight-bold">{{ $t('eshopDashboard.customers_with_other_products') }}</td>
                <td>{{ getChurnRateValue('annual', 'otherProduct') }}</td>
              </tr>
              <tr>
                <td class="font-weight-bold">{{ $t('eshopDashboard.churn_rate') }}</td>
                <td class="font-weight-bold">{{ getChurnRateValue('annual', 'churnRate') }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <app-payment-stats-filter v-if="activeTab === 'paymentStats'"></app-payment-stats-filter>

      <div class="card card-body" v-if="activeTab === 'paymentStats'">
        <table class="table table-striped table-bordered">
          <thead>
          <tr>
            <td></td>
            <td v-for="(month, index) in months" :key="`item-${index}`" class="font-weight-bold">{{ month }}</td>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(product, index) in products" :key="`item-${index}`">
            <td class="font-weight-bold">{{ product.name }}</td>
            <td v-for="(month, index) in months" :key="`item-${index}`">{{ getPaymentStatsByProductAndMonth(product, index + 1) }}</td>
          </tr>
          <tr>
            <td class="font-weight-bold">{{ $t('eshopDashboard.total') }}</td>
            <td v-for="(month, index) in months" :key="`item-${index}`" class="font-weight-bold">
              {{ getTotalPaymentStatsByMonth(month, index + 1) }}
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </template>
  </app-module-view>
</template>

<script>
import ModuleView from '../ModuleView'
import StoreService from '../../services/eshop/StoreService'
import ChurnRateFilter from './DashboardChurnRateFilter'
import PaymentStatsFilter from './DashboardSubscriptionPaymentStatsFilter'
import { Slovak } from 'flatpickr/dist/l10n/sk.js'

export default {
  name: 'Dashboard',
  data () {
    return {
      activeTab: 'churnRate',
      quarters: ['q1', 'q2', 'q3', 'q4'],
      months: Slovak.months.longhand
    }
  },
  computed: {
    churnRate () {
      return this.$store.getters['eshopDashboard/churnRate']
    },
    paymentStats () {
      return this.$store.getters['eshopDashboard/paymentStats']
    },
    isChurnRateLoaded () {
      return this.$store.getters['eshopDashboard/churnRateLoaded']
    },
    products () {
      return this.$store.getters['eshopSubscriptionProduct/all'].filter(product => product.enabled)
    },
    paymentStatsFilter () {
      return this.$store.getters['eshopDashboard/paymentStatsFilter']
    }
  },
  components: {
    appModuleView: ModuleView,
    appChurnRateFilter: ChurnRateFilter,
    appPaymentStatsFilter: PaymentStatsFilter
  },
  methods: {
    getChurnRateValue (type, param) {
      const churnRate = this.getChurnRateByType(type)
      if (churnRate.length === 1) {
        return churnRate[0][param] > 0 ? churnRate[0][param] : ''
      }
      return ''
    },
    getMonthlyChurnRateValue (month, param) {
      const monthlyChurnRate = this.getMonthlyChurnRate(month)
      if (monthlyChurnRate) {
        return monthlyChurnRate[param] > 0 ? monthlyChurnRate[param] : ''
      }
      return ''
    },
    getMonthlyChurnRate (month) {
      const monthlyChurnRate = this.getChurnRateByType('monthly')

      if (monthlyChurnRate) {
        const monthlyChurnRateForCurrentMonth = monthlyChurnRate.filter(churnRate => churnRate.month === month)
        if (monthlyChurnRateForCurrentMonth.length === 1) {
          return monthlyChurnRateForCurrentMonth[0]
        }
      }
      return null
    },
    getChurnRateByType (type) {
      return this.churnRate[type]
    },
    getPaymentStatsByProductAndMonth (product, month) {
      const productStats = this.paymentStats.filter(record => record.month === month && record.product === product.id)
      if (productStats.length === 1) {
        switch (this.paymentStatsFilter.paymentType) {
          case 'new':
            return productStats[0].new || ''
          case 'recurring':
            return productStats[0].recurring || ''
          default:
            return productStats[0].new + productStats[0].recurring || ''
        }
      }
      return ''
    },
    getTotalPaymentStatsByMonth (month) {
      const value = this.paymentStats.filter(record => record.month === month).map(record => {
        switch (this.paymentStatsFilter.paymentType) {
          case 'new':
            return record.new
          case 'recurring':
            return record.recurring
          default:
            return record.new + record.recurring
        }
      }).reduce((a, b) => {
        return a + b
      }, 0)
      return value > 0 ? value : ''
    }
  },
  mounted () {
    StoreService.fetchAll()
    this.$store.dispatch('eshopDashboard/fetchChurnRate')
    this.$store.dispatch('eshopDashboard/fetchPaymentStats')
  }
}
</script>
