<template>
  <section>
    <div class="card">
      <div class="card-body">
        <form @submit.prevent="filterPaymentStats">
          <div class="row">
            <div class="col-lg-1 col-md-6">
              <app-select
                v-model="filter.year"
                :options="years"
                :label="$t('eshopDashboard.filter.subscription_payment_stats.year')"
                track-by="id"
                id="filter_year"
                no-empty-value
              >
              </app-select>
            </div>
            <div class="col-lg-2 col-md-6">
              <app-select
                v-model="filter.paymentType"
                :options="paymentTypeValues"
                :label="$t('eshopDashboard.filter.subscription_payment_stats.payment_type')"
                id="filter_paymentType"
                no-empty-value
              >
              </app-select>
            </div>
            <div class="col-lg-3">
              <button class="btn btn-success pull-right m-t-30">
                {{ $t('eshopDashboard.filter.search') }}
              </button>
              <button class="btn btn-success pull-right m-t-30" data-test="subscriptionPaymentStats_reset_filter"
                      @click.prevent="resetFilters">
                {{ $t('eshopDashboard.filter.reset_filter') }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>
<script>
import Select from '../form/select/Select'
import PaymentStatsFilterModel from '../../model/eshop/DashboardSubscriptionPaymentStatsFilter'
import DashboardPaymentTypeMixin from '../mixins/valueObject/eshop/DashboardPaymentTypeMixin'

export default {
  name: 'SubscriptionPaymentStatsFilter',
  mixins: [DashboardPaymentTypeMixin],
  data () {
    return {
      filter: this._.cloneDeep(PaymentStatsFilterModel)
    }
  },
  computed: {
    products () {
      return this.$store.getters['eshopSubscriptionProduct/all']
    },
    years () {
      return this._.range(2020, 2026).map(year => ({ id: year, title: year }))
    }
  },
  components: {
    appSelect: Select
  },
  methods: {
    filterPaymentStats () {
      this.$store.commit('eshopDashboard/setPaymentStatsFilter', this.filter)
      this.$store.dispatch('eshopDashboard/fetchPaymentStats')
    },
    resetFilters () {
      this.filter = this._.cloneDeep(PaymentStatsFilterModel)
      this.filterPaymentStats()
    }
  },
  beforeMount () {
    this.filter = this.$store.getters['eshopDashboard/paymentStatsFilter']
  }
}
</script>

<style lang="scss" scoped>

</style>
