<template>
  <section>
    <div class="card">
      <div class="card-body">
        <form @submit.prevent="filterChurnRate">
          <div class="row">
            <div class="col-lg-1 col-md-6">
              <app-select
                v-model="filter.year"
                :options="years"
                :label="$t('eshopDashboard.filter.churn_rate.year')"
                track-by="id"
                id="filter_year"
                no-empty-value
              >
              </app-select>
            </div>
            <div class="col-lg-4 col-md-6">
              <app-select
                v-model="filter.product"
                :options="products"
                :label="$t('eshopDashboard.filter.churn_rate.product')"
                track-by="id"
                id="filter_product"
                optionTitle="name"
                no-empty-value
              >
              </app-select>
            </div>
            <div class="col-lg-3">
              <button class="btn btn-success pull-right m-t-30">
                {{ $t('eshopDashboard.filter.search') }}
              </button>
              <button class="btn btn-success pull-right m-t-30" data-test="churnRate_reset_filter"
                      @click.prevent="resetFilters">
                {{ $t('eshopDashboard.filter.reset_filter') }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>
<script>
import Select from '../form/select/Select'
import ChurnRateFilterModel from '../../model/eshop/DashboardChurnRateFilter'

export default {
  name: 'ChurnRateFilter',
  data () {
    return {
      filter: this._.cloneDeep(ChurnRateFilterModel)
    }
  },
  computed: {
    products () {
      return this.$store.getters['eshopSubscriptionProduct/all']
    },
    years () {
      return this._.range(2020, 2026).map(year => ({ id: year, title: year }))
    }
  },
  components: {
    appSelect: Select
  },
  methods: {
    filterChurnRate () {
      this.$store.commit('eshopDashboard/setChurnRateFilter', this.filter)
      this.$store.dispatch('eshopDashboard/fetchChurnRate')
    },
    resetFilters () {
      this.filter = this._.cloneDeep(ChurnRateFilterModel)
      this.filterChurnRate()
    }
  },
  beforeMount () {
    this.filter = this.$store.getters['eshopDashboard/churnRateFilter']
  }
}
</script>

<style lang="scss" scoped>

</style>
